.button {
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    color: white;
    background: var(--input-background);

    border-width: 1px;
    border-color: #ffffff20;
    border-style: solid;
    border-radius: var(--border-radius-light);
}

.button:active {
    background: var(--input-background-active);
}

.button:hover,
.button:focus {
    background: var(--input-background-hover);
    outline: none;
}

.button:active:hover,
.button:active:focus {
    background: var(--input-background-focus-hover);
}
