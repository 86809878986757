.list {
    grid-area: content;
    height: 100%;
}

.folder {
    margin-left: 20px;
}

.buttons {
    grid-area: margin-left;
    height: 100%;
}

.item {

}

.item > p {
    font-size: small;
}

.createForm {
    position: absolute;
    left: 50%;
    top: 50%;

}