.card {
    background-color: var(--background-card);
    border-radius: var(--border-radius-light);
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff0a;

    margin: 5px;
    padding: 5px;

    display: flex;
    flex-direction: column;

    box-shadow: var(--shadow-1);
    user-select: none;
}

.hoverCard {
    -webkit-transform:translateY(0);
    transition:all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hoverCard::before {
    content: "";
    border-radius: var(--border-radius-light);
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-3);
    opacity: 0;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
}

.hoverCard:hover::before {
    opacity: 1;
}

.hoverCard:hover {
    
}

.wide {
    grid-column: span 2;
}

.full {
    grid-column: span 4;
}

.content {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    justify-content: center;
}