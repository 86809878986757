.barWrapper {
    position: relative;
    height: 30px;
    margin: 12px 4px;
    
}

.bar {
    display: flex;
    flex-flow: row nowrap;
    
    height: 30px;

    border-radius: var(--border-radius-medium);
    overflow: hidden;
    background: var(--input-background);
}

.barPiece {
    height: 100%;
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.overlay {
    display: flex;
    position: absolute;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    top: 0px;

    font-size: 20px;

    pointer-events: none;
}

.tooltip {
    border-radius: var(--border-radius-light);
    font-size: calc(8px + 1vmin);
}

.legend {
    display: flex;
    flex-flow: row wrap;
    margin: -4px;
}

.entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px;
    font-size: calc(8px + 1vmin);
    background-color: var(--input-background);
    padding-right: 6px;
    border-radius: var(--border-radius-heavy);
}

.entryColor {
    width: 25px;
    height: 25px;
    margin-right: 4px;
    border-radius: var(--border-radius-medium);
}


.hover {
    -webkit-transform:translateY(0);
    transition:all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover::before {
    content: "";
    border-radius: var(--border-radius-light);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-3);
    opacity: 0;
    transition: all .1s cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
}

.hover:hover::before {
    opacity: 1;
}

.hover:hover {
    transform: scale(1.00, 1.5);
}