/* Browser */
.browser {

}

.browser:last-child {
    margin-bottom: 0px;
}

/* Group */
.group {
    display: grid;
    grid-template-columns: 1fr;

    margin-bottom: 12px;
}

.group.small {
    grid-template-columns: 1fr 1fr;
}

.group.medium {
    grid-template-columns: 1fr;
    
}

.group.large {
    grid-template-columns: 1fr;
}

.group.tree {
    
}

@media only screen and (min-width: 768px) {
    .group.small {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    
    .group.medium {
        grid-template-columns: 1fr 1fr;
        
    }
    
    .group.large {
        grid-template-columns: 1fr;
    }
}

/* Item */
.item {
    display: grid;
    grid-template-columns: 1fr auto;
}

.item span {
    justify-self: left;
}

.item .icon {
    width: 1.25em;
    height: 1.25em;

    background-color: cornflowerblue;

    border-radius: var(--border-radius-heavy);

    justify-self: right;
    align-self: center;
}

/* Path */
.folderPath {
    display: flex;
}

.folderPath a {
    color: #FFF;
    text-decoration: none;
}

.folderPath span {
    color: #BBB;
}