.picker {
    display: grid;
    grid-template: 
        "sat sat hue" max-content
        "hex color color" max-content /
        max-content max-content max-content;

    cursor: default;
}

.saturation {
    position: relative;
    grid-area: sat;

    width: 150px;
    height: 150px;

    margin: 4px;

    border-radius: 1px;
    overflow: hidden;

    box-shadow: var(--shadow-1);
}

.satPointer {
    width: 8px;
    height: 8px;
    margin: -5px;

    border: 1px solid #FFF;
    border-radius: 1px;

    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.25);
}

.hue {
    width: 12px;
    position: relative;

    margin: 4px;

    border-radius: 1px;
    overflow: hidden;

    box-shadow: var(--shadow-1);
}

.huePointer {
    width: 10px;
    height: 6px;
    margin: -4px 0px;

    border: 1px solid #FFF;
    border-radius: 1px;
    
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.75);
}

.hexInput {
    font-family: Consolas, monospace;
    width: 5em;
    margin: 0px 4px 4px;
}

.colors {
    grid-area: color;

    border-radius: 2px;
    overflow: hidden;

    width: calc(100% - 12px);
    height: 25px;
    margin: 0px 0px 0px 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

