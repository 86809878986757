.gym {
    grid-area: content;
    align-self: flex-start;
}

.buttons {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
}

.addExercise {
    width: 3em;   
    height: 3em;

    border-radius: var(--border-radius-heavy);
    box-shadow: var(--shadow-3);
    background: var(--background-card);
}

.addExercise:hover {
    background: var(--background-card-hover);
}

.addExercise:active {
    background: var(--background-card-active);
}

.addExercise svg {
    height: 2em;
    width: 2em;
    margin: 0.5em;
}