.checkbox {
    color: white;
    padding: 1px;

    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: small;
}

.checkbox span {
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.box {
    width: 1em;
    height: 1em;

    margin-right: 0.25em;

    background: var(--input-background);

    border-style: solid;
    border-width: 1px;
    border-color: #ffffff20;
    border-radius: var(--border-radius-light);

    cursor: default;
    transition: border-color .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box.checked {
    border-color: var(--input-active);
}

.box::before {
    content: "";
    width: 0.0em;
    height: 0.0em;
    margin: 0.5em;
    display: block;
    position: absolute;
    pointer-events: none;

    background: var(--input-active);

    border-width: 1px;
    border-radius: var(--border-radius-light);

    transition: width .1s cubic-bezier(0.165, 0.84, 0.44, 1), 
                height .1s cubic-bezier(0.165, 0.84, 0.44, 1),
                margin .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box.checked::before {
    width: 0.6em;
    height: 0.6em;
    margin: 0.2em;
}