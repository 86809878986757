.palette {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    
    margin: 4px;
    width: calc(100% - 12px);

    grid-area: palette;
}

.column {
    flex-direction: column;
}

.color {
    width: 50px;
    height: 50px;
    margin: 4px;
    border-radius: var(--border-radius-heavy);
    box-shadow: var(--shadow-1);
}

.hover {
    -webkit-transform:translateY(0);
    transition:all .2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover:after {
    content: "";
    border-radius: var(--border-radius-heavy);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-2);
    opacity: 0;
    transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hover:hover {
    transform: scale(1.01, 1.01);
}

.hover:hover:after {
    opacity: 1;
}
