.page {
    grid-area: content;
    margin: 0px auto 10em auto;

    display: grid;
    grid-template: 
        "forms" max-content
        "error" max-content /
        1fr;

}

.login {
    height: max-content;
    width: 50ch;

    display: grid;
    grid-template: 
        "login" max-content
        "center" max-content
        "register" max-content /
        1fr;
    justify-items: center;
    gap: 16px;

    padding: 16px;
    background: var(--background-1);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--shadow-3);
}

.login h1 {
    margin: 0px;
}

.loginForm, .registerForm {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 8px;
}

.loginForm {
    grid-area: login;
}

.registerForm {
    grid-area: register;
}

.errors {
    position: relative;
}

.error {
    position: absolute;
    width: 100%;
    box-sizing: border-box;

    visibility: hidden;
    margin-top: 12px;
    background-color: #C00;
    padding: 4px;
    border: 1px solid #FFF6;
    border-radius: var(--border-radius-light);
    box-shadow: var(--shadow-2);
}

.error.active {
    visibility: visible;
}
