.collection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-area: content;
}

@media only screen and (min-width: 768px) {
    .collection {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.uptime {
    display: grid !important;
    grid-template-columns: 1fr max-content 1fr;
}

.time {
    font-size: calc(15px + 3vmin);
    padding: 10px;
    grid-column: 2;
}

.fullTime {
    font-size: calc(6px + 1.5vmin);
    grid-column: 3;
    align-self: center;
}
