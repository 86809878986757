.content {
    display: grid;
    grid-template: 
        "color-picker-h harmony-h   harmony-h   harmony-h   harmony-h   harmony-h" max-content
        "color-picker   harmony     harmony     harmony     harmony     harmony" 1fr
        "color-picker   harmony     harmony     harmony     harmony     harmony" 1fr /
        5fr             1fr         1fr         1fr         1fr         1fr;
    grid-auto-rows: 1fr;

    grid-area: content;
}

.colorPickerHeader {
    grid-area: color-picker-h;
}

.harmoniesHeader {
    grid-area: harmony-h;
}

.colorPickerCard {
    grid-area: color-picker;
}

.harmonyCard {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-items: center;

}

.harmony {
    display: flex;
    flex-direction: row;
    background-color: var(--background-1);
    border-radius: var(--border-radius-heavy);
    margin: 4px 4px 12px 4px;
    height: max-content;
}

.paletteName {
    display: flex;
    flex-flow: column-reverse nowrap;
    width: 1em;
    margin: 8px 0px;
    padding: 4px 0px;
}

.harmony span {
    display: block;
    white-space: nowrap;
    transform: rotate(-90deg);
    transform-origin: center;
}

.paletteColors {
    width: 60px;
}