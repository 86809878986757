.textInputWrapper {
}

.textInput {
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    color: white;
    background: var(--input-background);

    border-style: solid;
    border-width: 1px;
    border-color: #ffffff20;
    border-radius: var(--border-radius-light);
}

.textInput:active, 
.textInput:focus {
    background: var(--background-3);
}


.textInput:active {
    background: var(--input-background-active);
}

.textInput:hover, 
.textInput:focus {
    background: var(--input-background-hover);
    outline: none;
}

.textInput:active:hover, 
.textInput:active:focus {
    background: var(--input-background-focus-hover);
}
