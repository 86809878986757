.contextMenu {
    position: absolute;
    display: grid;
    top: 0px;
    left: 0px;

    color: white;

    visibility: collapse;

    z-index: 10000;
}

.contextMenu.open {
    visibility: visible;
}

.menuItem {
    position: absolute;

    --radius: 25px;

    margin-top: calc(-1 * var(--radius));
    margin-left: calc(-1 * var(--radius));

    width: calc(2 * var(--radius));
    height: calc(2 * var(--radius));
    
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;

    border-radius: 50%;
    
    opacity: 0;
    pointer-events: none;

    transform: scale(0.7);
    transition: all 0s;
}

.menuItem > .menuItemCard {
    padding: 2px 8px 4px 8px;
}

.menuItem:hover > .menuItemCard::before {
    opacity: 1;
}

.menuItem:hover > .menuItemCard {
    background: var(--background-card-active);
}

.open .menuItem {
    opacity: 1;
    pointer-events: all;
    display: grid;
    grid-template-rows: max-content 0px;

    transform: scale(1);
    transition: all .05s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.01s cubic-bezier(1,.01,.9,.54);
}

.open .submenuOpen {
    opacity: 0;
    pointer-events: none;
}

.open .hidden {
    opacity: 0;
    pointer-events: none;
}

.hoverInfo {
    visibility: collapse;
    margin: -8px 0px -100% 0px;
    align-self: flex-start;
    font-size: x-small;

    background-color: var(--background-0);

    pointer-events: none;

    z-index: -1;
}

.hoverInfo.hover {
    visibility: visible;
}

.itemContent {
    display: flex;
    flex-direction: row;
    min-height: 10px;
}

.itemContent img {
    display: inline-block;
    align-self: center;
    height: 1em;
    width: 1em;
    margin: -2px 4px -2px -2px;
}

.center {
    position: absolute;

    margin-top: -20px;
    margin-left: -20px;

    width: 40px;
    height: 40px;

    border-radius: 50%;
}

.svg {
    position: absolute;
    justify-self: center;
    align-self: center;

    pointer-events: all;
    opacity: 0.5;
}

.svg path {
    stroke: #BBBBBB;
    stroke-width: 2px;
    stroke-linecap: round;
}

.svg circle {
    fill: #BBBBBB;
}

.dot {
    position: absolute;

    margin-top: -5px;
    margin-left: -5px;

    width: 10px;
    height: 10px;

    background: #a7cadc;

    border-radius: 50%;

    opacity: 0;
    pointer-events: none;

    z-index: -1;
}

.open .dot {
    opacity: 1;
}
