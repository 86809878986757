.import {
    display: grid;
    gap: 4px;

    grid-template: 
        "file file" max-content
        "preview preview" max-content
        "overwrite layer" max-content /
        1fr 1fr;
}

.export {
    display: grid;
    gap: 4px;
    grid-template:
        "image-desc image-in" max-content
        "scale-desc scale-in" max-content
        "download   download" max-content
        "padding    padding"  1fr /
        6ch         1fr;
    align-items: center;
}

.export span {
    justify-self: right;
}

.export .download {
    grid-area: download;
}

.preview {
    grid-area: preview;

    width: 350px;
    margin: 10px;

    image-rendering: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: pixelated;

    align-self: center;
    justify-self: center;
}
