.toolbar {
    display: grid;
    grid-template-columns: 1fr; /* 1fr */
    width: calc(100% - 4px);
    margin: 4px 2px;
}

.tool {
    width: calc(100% - 4px);
    margin: 2px;
    padding: 4px;
    box-sizing: border-box;

    image-rendering: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: pixelated;

    background: var(--input-background);

    border-width: 1px;
    border-color: #ffffff20;
    border-style: solid;
    border-radius: var(--border-radius-light);

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.tool.active {
    border-color: var(--input-active);
}

.tool:active {
    background: var(--input-background-active);
}

.tool:hover {
    background: var(--input-background-hover);
}

.tool:active:hover {
    background: var(--input-background-focus-hover);
}

.save {
    margin-left: auto;
    margin-right: 2em;
}

.mirror {
    margin: 0px 4px;
}

.dithering {
    margin: 0px 4px;
}

.toolOptions {
    z-index: 11;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: top;
    width: 100%;
    height: 75px;
    background: var(--background-0);
    filter: brightness(120%);
    box-shadow: var(--shadow-3);
    cursor: initial;

    color: white;
}

.toolOptions > separator {
    width: 1px;
    height: calc(100% - 16px);
    margin: 8px 4px;
}

.activeColors {
    position: relative;
    margin: 8px;
    width: 60px;
    height: 50px;

    z-index: 0;
}

.activeColors > div:first-of-type {
    margin: -2px;
    background: var(--background-0);
    border-width: 2px;
    border-color: var(--background-0);
    border-style: solid;
    border-radius: calc(var(--border-radius-heavy) + 1px);

    position: absolute;
    z-index: 1;
}

.activeColors > div:last-of-type {
    margin-top: 10px;
    margin-left: 20px;

    position: absolute;
    z-index: -1;
}

.colorPrimary {
    width: 40px;
    height: 40px;

    border-radius: var(--border-radius-heavy);
}

.colorSecondary {
    width: 40px;
    height: 40px;

    border-radius: var(--border-radius-heavy);
}

.option {
    position: relative;
    height: 100%;
    display: grid;
    grid-template: 
        "name" max-content
        "img" 1fr
        "dropdown-arrow" 0px
        "dropdown" 0px/
        1fr;
    
    min-width: 50px;
}

.dropdown {
    grid-area: dropdown;
    display: none;
    position: absolute;
    z-index: 1;
    margin-top: 8px;

    background-color: var(--background-1);
    box-shadow: var(--shadow-3);
    border-radius: var(--border-radius-light);

    flex-direction: column;

    width: max-content;
    min-width: 100px;
    max-width: 200px;
}



.dropdownArrow {
    display: none;
    grid-area: dropdown-arrow;

    position: relative;

    height: 10px;
    margin-top: -2px;
    width: 40px;
    justify-self: start;
    overflow: hidden;
}

.dropdownArrow::before {
    content: "";
    position: absolute;
    z-index: 2;
    border: solid 10px transparent;
    border-color: var(--background-1);
    border-radius: 2px;
    box-shadow: var(--shadow-3);
    transform: rotate(45deg);
    height: 0px;
    width: 0px;
    margin-left: -10px;
    margin-top: 5px;
}

.option.active .dropdown {
    display: flex;
}

.option.active .dropdownArrow {
    display: block;
}

.dropdownItem {
    display: block;
    width: calc(100% - 16px);
    margin: 2px 0px;
    padding: 4px 8px;

    text-align: left;
}

.dropdownItem:hover {
    background: var(--background-2);
}
