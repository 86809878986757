.navigationPlaceholder {
    height: var(--navigation-height);
}

.navigation {
    background-color: #393939;
    box-shadow: var(--shadow-1);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;

    font-size: var(--navigation-font-size);

    z-index: 1000;
}

.navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: var(--navigation-height);
}
  
.navigation li {
    height: 100%;
    float: left;
}

.navigation li a {
    display: block;
    height: 100%;
    min-width: var(--navigation-width);

    color: white;
    text-align: center;
    text-decoration: none;
}
  
.navigation li a:hover:not(.active) {
    background-color: var(--background-0);
}

.navigation li a.active {
    height: calc(100% - 1.5px);
    border: solid #8560ce;
    border-width: 0px 0px 2px 0px;
}

.footer {
    background-color: #1a1a1a;
    color: white;
    height: 200px;
}
