.selector {

    display: flex;
    flex-flow: row wrap;

    border-radius: var(--border-radius-light);
    padding: 2px 4px;
    border-width: 2px 2px 0px 2px;
}

.option {
    display: block;
    background-color: var(--input-background);
    margin: 0px 2px;
    padding: 2px 12px;
    border-radius: var(--border-radius-medium);
    box-shadow: var(--shadow-1);

    flex: 1;
}

.option span {
    user-select: none;
}

.option:hover {
    background-color: var(--input-background-hover);
}

.option.selected {
    background-color: var(--input-background-focus);
}

.option.selected:hover {
    background-color: var(--input-background-focus-hover);
}

.first {
    border-radius: var(--border-radius-heavy) var(--border-radius-medium) var(--border-radius-medium) var(--border-radius-heavy);
    margin: 0px 2px 0px 0px;
}

.last {
    border-radius: var(--border-radius-medium) var(--border-radius-heavy) var(--border-radius-heavy) var(--border-radius-medium);
    margin: 0px 0px 0px 2px;
}