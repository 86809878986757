.palette {
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    display: grid;
    align-items: center;
    justify-items: center;

    grid-template: 
        "padding-top padding-top padding-top padding-top" 1fr
        "padding-left circle config padding-right" max-content
        "padding-bot padding-bot padding-bot padding-bot" 1fr / 
        1fr max-content 0px 1fr;
}

.center {
    grid-area: circle;

    background: var(--background-1);
    /* 175px */
    border: 1px solid var(--background-3);
    border-radius: 50%;
    width: 390px;
    height: 390px;
    
    display: grid;
    align-items: center;
    justify-items: center;

    box-shadow: var(--shadow-4);
}

.center > * {
    grid-row: 1;
    grid-column: 1;
}

.center svg {
    /* 1 : 0.7649806793932755 */
    /* 35.581 50.226 - 1:0.7084179508621035*/
    grid-row: 1;
    grid-column: 1;

    width: 380px;
    height: 380px;

    -webkit-filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));
}

.center svg g {
    transform-origin: 0px 50px;
}

.center svg g:nth-child(1) {
    transform: rotate(0deg);
}
.center svg g:nth-child(2) {
    transform: rotate(45deg);
}
.center svg g:nth-child(3) {
    transform: rotate(90deg);
}
.center svg g:nth-child(4) {
    transform: rotate(135deg);
}
.center svg g:nth-child(5) {
    transform: rotate(180deg);
}
.center svg g:nth-child(6) {
    transform: rotate(225deg);
}
.center svg g:nth-child(7) {
    transform: rotate(270deg);
}
.center svg g:nth-child(8) {
    transform: rotate(315deg);
}

.configButton {
    grid-area: config;
    align-self: start;
    justify-self: end;
    display: grid;
    
    color: white;
    font-family: Consolas, monospace;

    width: 190px;
    height: 190px;
    margin: 2px;

    pointer-events: all;

    fill: var(--background-0);

    -webkit-filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));

    z-index: -1;
}

.configButton > * {
    justify-self: end;
    display: grid;
    align-items: center;
    justify-items: center;

    width: 25px;
    height: 25px;
    margin: 4px;

    border-radius: 50%;

    background: var(--background-1);
}

.configBackground {
    background: var(--background-0);
    
    padding: 8px;


    display: grid;
    align-items: center;
    justify-items: center;
    gap: 16px;
    grid-template: 
        "picker circle config" 1fr / 
        max-content max-content max-content;
}

.configBackground .center {
    background: none;
    /* 175px */
    border: none;
    width: 390px;
    height: 390px;
    
    display: grid;
    align-items: center;
    justify-items: center;

    box-shadow: none;
}

.picker {
    grid-area: picker;
    height: 400px;
    padding: 2px;
}

.config {
    grid-area: config;
    width: 200px;
    height: 400px;
}
