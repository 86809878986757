.sliderCollection {
    display: grid;
    grid-template:
        "name slider" 1fr /
        1fr 39fr;
    align-items: center;
    justify-items: center;
    margin: 0px 12px;
}

.sliderCollection span {
    padding: 0px 10px 3px 0px;
    grid-column-start: name;
}

.colorSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 26px;
    outline: none;
    border-radius: var(--border-radius-medium);
    display: block;
    grid-column-start: slider;
}
  
.colorSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: transparent;
    cursor: e-resize;
    border-radius: var(--border-radius-medium);
    border: solid white 2.5px;
}
  
.colorSlider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: transparent;
    cursor: e-resize;
    border-radius: var(--border-radius-medium);
    border: solid white 2.5px;
}

.hue {
    background: linear-gradient(to right, #f00 0%, #ff0 16.6665%,#0f0 33.3333%, #0ff 50%, #00f 66.6666%, #f0f 83.33325%, #f00 100%);
}

/* RGB */
.red {
    background: linear-gradient(to right, black 0%, red 100%);
}

.green {
    background: linear-gradient(to right, black 0%, green 100%);
}

.blue {
    background: linear-gradient(to right, black 0%, blue 100%);
}

/* CMYK */
.cyan {
    background: linear-gradient(to right, white 0%, cyan 100%);
}
.magenta {
    background: linear-gradient(to right, white 0%, magenta 100%);
}
.yellow {
    background: linear-gradient(to right, white 0%, yellow 100%);
}
.black {
    background: linear-gradient(to right, white 0%, black 100%);
}