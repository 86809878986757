.pixel {
    display: grid;

    grid-template: 
        "top top top" max-content
        "left center right" 1fr /
        50px 1fr 200px;

    background: #0801;

    width: 100%;
    height: 100%;
}

.canvasContainer {
    grid-area: center;
    display: grid;

    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}

.canvasWrapper {
    display: grid;
    height: min-content;
    width: min-content;
}

.canvas {
    /* 
    border-radius: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: #FFF2; 
    */

    image-rendering: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    
    grid-row: 1;
    grid-column: 1;
}

.mirrorX {
    width: 1px;
    height: 100100%;
    margin-top: -50000%;
    margin-bottom: -50000%;

    background: #F007;

    align-self: center;
    justify-self: center;

    grid-row: 1;
    grid-column: 1;

    pointer-events: none;
}

.mirrorY {
    width: 100100%;
    height: 1px;
    margin-left: -50000%;
    margin-right: -50000%;

    background: #00F7;

    align-self: center;
    justify-self: center;

    grid-row: 1;
    grid-column: 1;

    pointer-events: none;
}

.gridOverlay {
    width: 100%;
    height: 100%;

    grid-row: 1;
    grid-column: 1;

    --lines: 32;

    --color: #0008;
    --baseline: calc(100% / var(--lines));

    background: repeating-linear-gradient(
        to bottom,
        var(--color),
        var(--color) 1px,
        transparent 1px,
        transparent var(--baseline)
    ), repeating-linear-gradient(
        to right,
        var(--color),
        var(--color) 1px,
        transparent 1px,
        transparent var(--baseline)
    );

    pointer-events: none;
}

.paletteColor {
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 0px;
}

.activeColors {
    position: relative;
    margin: 8px;
    width: 80px;
    height: 80px;

    z-index: 0;
}

.activeColors > div:first-of-type {
    margin: -2px;
    background: var(--background-0);
    border-width: 3px;
    border-color: var(--background-0);
    border-style: solid;
    border-radius: calc(var(--border-radius-heavy) + 1px);

    position: absolute;
    z-index: 1;
}

.activeColors > div:last-of-type {
    margin-top: 30px;
    margin-left: 30px;

    position: absolute;
    z-index: -1;
}

.imageName {
    margin: 4px 4px 0px 4px;
}

.download {
    width: calc(100% - 8px);
    margin: 4px;
}

.panelLeft {
    grid-area: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: var(--background-0);
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #ffffff0a;
    box-shadow: var(--shadow-3);
    cursor: auto;

    z-index: 10;
}

.panelLeft > span {
    color: white;
    font-size: 0.75em;
}

.canvasSize {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin: 4px;
}

.canvasSize span {
    grid-column: span 2;
    color: white;
    font-size: 0.75em;
}

.canvasSize input[type=text] {
    text-align: center;
}

.resizeButton {
    grid-column: span 2;
}


.separatorHeader {
    display: grid;
    width: 100%;
    color: var(--background-2);
    font-size: 0.75em;
    margin-top: 2px;

    align-items: center;

    grid-template-columns: 1fr max-content 1fr;
}

.separatorHeader span {
    margin: -2px -4px -2px -4px;
}

.separatorHeader separator {
    height: 1px;
    width: calc(100% - 16px);
    margin: 4px 8px;
    background: var(--background-1);
}


.panelRight {
    grid-area: right;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: var(--background-0);
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #ffffff0a;
    box-shadow: var(--shadow-3);
    cursor: auto;

    z-index: 10;
}

.previewCanvasWrapper {
    --preview-size: calc(200px - 48px);
    display: grid;
    margin: 8px;
    padding: 4px;
    width: var(--preview-size);
    height: var(--preview-size);

    border-width: 1px;
    border-color: #ffffff20;
    border-style: solid;
    border-radius: var(--border-radius-light);
}

.previewCanvas {
    image-rendering: auto;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: pixelated;

    align-self: center;
    justify-self: center;
}

.file {
    display: grid;
    width: calc(100% - 8px);
    margin: 4px;

    grid-template: "import export" max-content / 1fr 1fr;
    gap: 4px;
}

.grid2x2 {
    display: grid;
    grid-template: 
        "a b" max-content
        "c d" max-content /
        1fr 1fr;
}
