.hiddenInput {
    position: absolute;
    left: 80px;
    top: 0px;
    width: 0px;
    height: 0px;
    visibility: hidden;
    overflow: hidden;
}

.color {
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius-heavy);
    box-shadow: var(--shadow-3);
}