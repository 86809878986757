.hexField {
    font-family: Consolas, 'Courier New', monospace;

    padding: 0px 10px;
    border: none;

    display: flex;
    flex-direction: row;

    margin-right: 0.6em;

    font-size: 1.5em;
}

.border .hexField {
    background-color: var(--input-background);
    border-radius: var(--border-radius-medium);
    margin-right: 0px;
}

.border {
    padding: 1px;
    border: solid 1px var(--input-border);
    background-color: var(--input-border);
    border-radius: calc(var(--border-radius-medium) + 1px);
    height: min-content;
}

.hexField .hex {
    width: 4em;
    display: block;
    word-wrap: break-word;
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
    column-gap: 0.1em;
    -webkit-column-gap: 0.1em;
    -moz-column-gap: 0.1em;
    grid-column: span 3;
}

.hexField [contenteditable]{
    text-transform: uppercase;
    z-index: 5;
    min-height: 1em;
}

.hexField span {
    padding-right: 2px;
}

.textContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.1em;
    width: 4em;
}

.color {
    position: relative;
    display: block;
    height: calc(1em + 4px);
    margin: calc(-1em - 4px) 0px -2px 0px;

    border-radius: 1px;
    z-index: 0;
    background-color: teal;
}

.color::after {
    position: relative;
    display: block;
    height: 3px;
    margin-top: calc(1em + 3px);

    content: "";

    border-radius: 1px;
    box-shadow: 0px 0px 2px 1px #0002;
    z-index: 1;
}

.red {
    background: linear-gradient(to top, #F003 0%, #F000 75%); 
}

.red::after {
    background: #F00;
}

.green {
    background: linear-gradient(to top, #0F03 0%, #0F00 75%); 
}

.green::after {
    background: #0F0;
}

.blue {
    background: linear-gradient(to top, #00F3 0%, #00F0 75%); 
}

.blue::after {
    background: #00F;
}
