.collection {
    border-width: 2px;
    border-color: greenyellow;
    border-style: solid;
}

.collection.empty {
    border-color: cornflowerblue;
}


.collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.splitter {
    height: 12px;
    width: 100%;
}

.items {
    display: grid;
    grid-template-columns: 1fr;
}

@media only screen and (min-width: 768px) {
    .collections {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .items {
        grid-template-columns: 1fr 1fr;
    }
}