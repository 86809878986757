.layers {
    width: calc(100% - 8px);
    box-sizing: border-box;
    margin: 4px;

    color: white;

    /*
    border-width: 1px;
    border-color: #ffffff20;
    border-style: solid;
    border-radius: var(--border-radius-light); 
    */

    font-size: small;
}

.layersContainer {
    display: flex;
    flex-direction: column-reverse;
    
    row-gap: 2px;
    margin: 0px 0px 8px 0px;

    width: 100%;
}

.layer {
    display: grid;
    grid-template-columns: 24px 1fr max-content;

    height: 24px;

    text-align: left;

    border-width: 1px;
    border-color: #ffffff20;
    border-style: solid;
    border-radius: var(--border-radius-light);
}

.layer:hover {
    background: var(--input-background-hover);
}

.layer.selected {
    background: var(--input-background);
    /* background: var(--input-background-active); */
}

.layer.selected:hover {
    background: var(--input-background-focus-hover);
}

.icon {
    background: #000;
}

.label {
    margin: 0px 4px;
    align-self: center;
}

.visibility {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px; /* remove */
    margin: 4px;

    align-self: center;

    background: var(--background-1);

    border-radius: var(--border-radius-light); 
}

.buttonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.button > * {
    height: 24px;
}
