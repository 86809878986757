.node {
    position: absolute;
    width: 0%;
    height: 0px;

    display: grid;
    grid-template-rows: 0px 0px;
    grid-template-columns: 0px 1fr 0px 1fr 0px;
    grid-template-areas: 
        /* "none-l none-l card   none-r  none-r" */
        "card   card   card   card    card"
        "left   c-left center c-right right";

    transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1), left .2s cubic-bezier(0.165, 0.84, 0.44, 1);

    width: 26px;
    margin-left: -13px;
}

.expand {
    width: calc(max(var(--expandedWidth), 32px));
    margin-left: calc(max(var(--expandedWidth), 32px) / -2);
    grid-template-columns: 0px 1fr 6px 1fr 0px;
}

.innerCircle {
    display: block;
    grid-area: center;

    border-radius: 50%;
    width: 15px;
    height: 15px;
    align-self: center;
    justify-self: center;
    background: var(--color);

    transition: inherit;

    z-index: 2;
}

.expand .innerCircle {
    filter: brightness(80%);
}

.node:hover .innerCircle {
    filter: brightness(80%);
}

.outerCircle {
    display: block;
    grid-area: center;

    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: var(--color);
    width: 20px;
    height: 20px;
    align-self: center;
    justify-self: center;

    background: radial-gradient(circle, #000A 0%, #0002 100%);
    box-shadow: var(--shadow-1);

    transition: inherit;

    z-index: 2;
}

.expand .outerCircle {
    width: 26px;
    height: 26px;
}

.radiusIndicatorLeft {
    display: block;
    grid-area: left;

    align-self: center;
    justify-self: left;

    border-radius: 20px;
    border-width: 3.5px;
    border-style: solid;
    border-left-color: var(--color);
    border-right-color: transparent;
    border-top-color: var(--color);
    border-bottom-color: transparent;

    pointer-events: none;

    width: 20px;
    height: 20px;

    transform: rotate(-45deg);
    transition: inherit;
}

.radiusIndicatorRight {
    display: block;
    grid-area: right;

    align-self: center;
    justify-self: right;

    border-radius: 20px;
    border-width: 3.5px;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: var(--color);
    border-top-color: transparent;
    border-bottom-color: var(--color);

    pointer-events: none;

    width: 20px;
    height: 20px;

    transform: rotate(-45deg);
    transition: inherit;
}

.expand .radiusIndicatorLeft {
    width: 26px;
    height: 26px;
    z-index: 5;
}

.expand .radiusIndicatorRight {
    width: 26px;
    height: 26px;
    z-index: 5;
}

.bar {
    height: 4px;
    
    width: calc(100% - 13px);
    margin-left: 1px;
    margin-right: 1px;
    background: linear-gradient(90deg, #000A 0%, #0004 30%, #0004 70%, #000A 100%);
    background: var(--color);
    filter: brightness(80%);

    pointer-events: none;

    align-self: center;
    transition: inherit;
    z-index: 0;
}

.cardContainer {
    grid-area: card;
    align-self: end;
    justify-self: center;
    visibility: collapse;
    opacity: 0;

    width: 200px;
    margin-bottom: -10px;

    transition: inherit;
    z-index: 0;
}

.node:hover .cardContainer {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.cardContainer card {
    margin-bottom: 40px;
}

.node:hover .cardContainer card {
    animation-name: floatIn;
    animation-duration: .4s;
} 

@keyframes floatIn {
    from {margin-bottom: 60px;}
    to {margin-bottom: 40px;}
}

.cardContainer h4 {
    font-size: medium;
    margin: 0.5em 0 0.5em 0;
}

.cardContainer span {
    font-size: small;
}

.cardContainer div {
    justify-content: start;
}