.window {
    display: grid;
    grid-template: 
        "sat hue" max-content /
        max-content max-content;
    margin: 0px;
}

.saturation {
    position: relative;

    width: 75px;
    height: 75px;

    border: 1px solid #FFF1;
    margin: 2px;
}

.hue {
    min-width: 8px;
    position: relative;

    border: 1px solid #FFF1;
    margin: 2px;
}
