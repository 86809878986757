.commandAlert {
    position: fixed;

    display: flex;

    left: 0;
    bottom: 0;

    color: white;
    font-size: smaller;

    pointer-events: none;
    opacity: 0;

    z-index: 1001;

    transition: all .1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.commandAlert.show {
    opacity: 0.7;
}

.card {
    padding: 2px 8px 2px 4px;
    margin: 4px;
}

.cardContent {
    flex-grow: 1;

    min-height: 10px;
    align-self: flex-start;
}