.content {
    display: grid;
    grid-template:
        "variables visualizer" 1fr /
        1fr 1fr;
    width: 100%;
    grid-area: content;
    overflow: hidden;
}

.category h3 {
    text-transform: capitalize;
}

.variables {
    grid-area: variables;
    
    width: 100%;
    height: 100%;
    padding: 20px 0px;
}

.variable:hover {
    background-color: var(--background-card-hover);
}

.variable.active {
    background-color: var(--background-card-active);
    transform: scale(1.005, 1.005);
}

.variable.active:hover {
    transform: scale(1.01, 1.01);
}


/* Cards */
.colorCard {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-items: center;
    align-items: center;

    height: 100%;
}

.colorCard .colorContainer {
    background-color: #fff;
    border-radius: 3px;
    border: solid #fff 1px;
    padding: 1px;
}

.colorCard .color {
    padding: 10px 30px;
    border-radius: 2px;
    text-transform: uppercase;
    width: 100px;
}

/* Visualizers / Selectors */
.visualizer {
    position: relative;
    grid-area: visualizer;
    width: 100%;
    height: 100%;
}

.visualizerAbs {
    position: absolute;
    width: 100%;
}

.visualizerFixed {
    position: fixed;
    top: 75px;
    left: 0px;
    width: 40%;
    margin: 0px 10% 0px 50%;
}