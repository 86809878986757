.windowRoot {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    display: grid;

    cursor: default;

    z-index: 100;
}

.overlay {
    background: #0005;
    pointer-events: all;
}

.window {
    position: absolute;
    display: grid;
    align-self: center;
    justify-self: center;
    
    min-width: 250px;
    min-height: 150px;

    background: var(--background-0);
    color: white;
    border: 1px solid #FFF1;
    border-radius: var(--border-radius-light);
    box-shadow: var(--shadow-3);

    grid-template:
      "header" 2.4rem
      "content" 1fr /
      1fr;
}

.header {
    font-size: 1.6rem;
    display: grid;
    grid-area: header;
    width: 100%;
    height: calc(100% + 1);
    margin: -1px 0px 0px 0px;

    border-bottom: 1px solid #FFF1;

    cursor: move;

    grid-template:
      "title" "close" 1fr /
      1fr max-content;
}

.title {
    font-size: 1.0em;
    margin: 0px;
}

.close {
    font-size: 1.5em;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
    outline: inherit;
    
    width: 0.8em;
    height: 0.8em;
    padding: 0.35em;

    align-self: center;
    justify-self: center;

    opacity: 0.5;
}

.close:hover {
    opacity: 0.7;
}

.content {
    margin: 4px;
}
