.timeline {
}

.title {
    
}

.nodes {
    position: relative;
}

.line {
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    left: 0;
    right: 0;
    margin-top: -2px;
    background: #9F4444;
}

.color {
    width: 4px;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    
    border-radius: var(--border-radius-light);
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff0a;

    box-shadow: rgba(0, 0, 0, 0.15) 1px 0px 1px 0px; 
}