html::-webkit-scrollbar {
  display: none;
}

html {
  font-size: 62.5%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  --navigation-font-size: calc(10px + 2vmin);
  --navigation-height: calc(17.5px + 3.5vmin);
  --navigation-width: 5em;
  --font-size: calc(1em + 0px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3em;
}

input {
  font-size: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.frontPage {
  background-color: var(--background-1);
  min-height: calc(30vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page {
  background-color: var(--background-0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  display: grid;
  grid-template:
      "margin-left content margin-right" 1fr /
      4px 98fr 4px;
  
}

@media only screen and (min-width: 768px) {
  .page {
    grid-template:
      "margin-left content margin-right" 1fr /
      10fr 80fr 10fr;
  }
}

.App {
  text-align: center;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

/* React tiny link */

.react_tinylink_card {
  color: white;
  background-color: var(--background-card);
  border-radius: var(--border-radius-light);
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff0a;
  margin-top: 4px;
  max-width: 100%;
  height: max-content;
}

.react_tinylink_card:hover {
  background-color: var(--background-card-hover);
}

.react_tinylink_card_content_wrapper {
  display: grid;
  grid-auto-rows: max-content;
  padding: 0px 8px;
}

.react_tinylink_card_media {
  flex: 0 0 50px;
  height: 50px;
}

.react_tinylink_card_media > img {
  height: 50px;
}

.react_tinylink_card_content_header {
  font-size: small;
}

.react_tinylink_card_content {
  margin: 0px;
  font-size: x-small;
  line-height: normal;
}

.react_tinylink_footer {
  visibility: collapse;
  height: 0px;
}
